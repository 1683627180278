import React from 'react';
import { FormWrap, Input, Button, toast } from 'day8-ui';
import analyticsHelpers, { GA4_TRIGGERS } from 'day8-react/build/helpers/analytics';
import { useForm } from 'day8-react/build/helpers/hooks';
import { validateEmail } from 'day8-react/build/helpers/formatting';
import { useRouter } from 'next/router';

type KlaviyoSignupProps = {
  id?: string
  addressBookId: string
  buttonText?: string
  customFields?: {
    Source?: string
    Persona?: string
    "MOFU__LP__DESTINATION"?: string
    Variant?: string
    Stage?: string
  }
}

const KlaviyoSignup: React.FC<KlaviyoSignupProps> = ({ addressBookId, customFields, id, buttonText = "Let's Go" }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const router = useRouter();
  const { formData, handleChange } = useForm({
    email: '',
    addressBookId,
    ...customFields,
    "MOFU | LP | DESTINATION": customFields?.MOFU__LP__DESTINATION,
    URL: router.asPath,
  });
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      toast.error('Please enter a valid email address');
      return;
    }
    setSubmitting(true);
    fetch('/api/marketing/add-contact', {
      method: 'POST',
      body: JSON.stringify({
        ...formData,
        email: formData.email.trim()
      }),
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          analyticsHelpers.contactSubscribe(formData);
          toast.success("Sign up successful!");
          handleChange({ target: { name: 'email', value: '' } });
        } else {
          toast.error("Something went wrong... Please try again later");
        }
      })
      .catch(e => {
        toast.error("Something went wrong... Please try again later");
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <FormWrap id={id || GA4_TRIGGERS.SIGNUP_FORM_ID} onSubmit={handleSubmit} loading={submitting}>
      <div className='dui-flex dui-flex-col sm:dui-flex-row dui-gap-4 dui-items-center' >
        <Input
          className='dui-text-primary-950'
          type="email"
          placeholder="Email"
          name="email"
          onChange={handleChange}
          value={formData.email}
        />
        <Button className='dui-shrink-0 dui-w-full sm:dui-w-auto' type="submit" >{buttonText}</Button>
      </div>
    </FormWrap>
  )
}

export default KlaviyoSignup;