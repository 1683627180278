import { Button, Card, Carousel } from "day8-ui";
import Review from "./front-page/Review";
import { PiCaretRightLight, PiCaretLeftLight } from "react-icons/pi";
import { StopElement } from "./Product2025Wrapper";
import Spacer from "./Spacer";

interface ReviewsSliderProps {
  heading?: string
  paragraph?: string
  reviews?: StopElement[]
}

const SLIDES_TO_SHOW = 2;

const ReviewsSlider = ({ heading = "Join a global community", paragraph = "Magical experiences and a curated crowd.", reviews }: ReviewsSliderProps) => {
  return (
    <div className='dui-bg-secondary-100 dui-border-solid dui-border-y-4 dui-border-secondary-500 dui-max-w-full dui-overflow-hidden'>
      <Spacer />
      <div className="dui-container dui-mx-auto">
        <div className='dui-text-center'>
          <h3 className="dui-text-6xl dui-font-heading !dui-font-normal dui-mb-6 dui-text-primary-950 dui-uppercase">{heading}</h3>
          <p className="dui-mb-8 lg:dui-text-lg">{paragraph}</p>
        </div>

        {reviews && (
          <Carousel
            className="dui-w-full"
            settings={{
              arrows: reviews.length > SLIDES_TO_SHOW ? true : false,
              dots: false,
              infinite: true,
              nextArrow: <div><Button buttonType="CAROUSEL" className='dui-rounded-none !dui-bg-transparent dui-w-16 dui-h-16 dui-hidden md:dui-block dui-absolute dui-right-[-30px] dui-top-1/2 dui-transform dui--translate-y-1/2'><PiCaretRightLight className='dui-text-2xl' /></Button></div>,
              prevArrow: <div><Button buttonType="CAROUSEL" className='dui-rounded-none !dui-bg-transparent dui-w-16 dui-h-16 dui-hidden md:dui-block dui-absolute dui-left-[-30px] dui-top-1/2 dui-transform dui--translate-y-1/2'><PiCaretLeftLight className='dui-text-2xl' /></Button></div>,
              slidesToScroll: 1,
              slidesToShow: SLIDES_TO_SHOW,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    dots: true,
                  }
                }
              ]
            }}
            includePageStatus={false}
            slides={reviews.map((review, index) => (
              <div className='md:dui-px-4'>
                <Review
                  reviewSocialTag={"@" + review.title?.rendered?.replace(" ", "") || "review"}
                  reviewStars={5}
                  reviewContent={review.excerpt?.rendered || "review"}
                  reviewAuthor={review.title?.rendered || "review"}
                />
              </div>
            ))
            }
          />
        )}
      </div>
      <Spacer />
    </div>
  )
}

export default ReviewsSlider;